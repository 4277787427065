<!-- Mto particular de Cementerios -->

<template>
  <div class="cemen_M" v-if="schema">
    <!-- Cabecera -->
    <div class="cabecera">
      <base_Header
        v-if="Entorno.header.header"       
        :Entorno="Entorno.header"
        @onEvent="event_Header">
      </base_Header>
    </div>

    <v-sheet :elevation="4">
     
      <!-- Slot con los controles del Mto -->
      <div class="contenedor" style="width:1200px">
        <div class="pt-2 d-flex justify-left" style="justify-content:space-between;width:270px">
          <!-- Botones Mto -->        
          <btramto
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="m"
              :estado="estado"
              @onEvent="event_btra_Mto">        
          </btramto>

          <!-- Ventana de Documentos -->
          <common_Docs
            :padre="stName"
            :id="record.id"       
            show="1"
            tip="9">
          </common_Docs>
        </div>

        <div class="conflex" style="padding-top:10px">
          <div class="columna" style="width:60%">
            <v-text-field
              style="font-weight: bold;"
              v-bind="$input"
              v-model="schema.ctrls.name.value"
              :label="schema.ctrls.name.label"
              :disabled="!is_edit"
            ></v-text-field>

            <ctrlfinder
              finderName="atlas_F"
              :schema="schema.ctrls.atlas"
              :edicion="is_edit"
            ></ctrlfinder>

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.dire.value"
              :label="schema.ctrls.dire.label"
              :disabled="!is_edit"
            ></v-text-field>

            <ctrlfinder
              finderName="funes_F"
              :schema="schema.ctrls.fun_id"
              :edicion="is_edit"
            ></ctrlfinder>
          </div>

          <div class="columna">
            <v-select
              style="width:18rem"
              v-bind="$select"
              v-model="schema.ctrls.tipo.value"
              :items="$store.state.datos_iniciales.tipo_cem"
              :disabled="!is_edit"
              :label="schema.ctrls.tipo.label"
              item-value="id"
              item-text="name"
            ></v-select>

            <ctrlgeo
              :schema="schema"
              name_ctrl="gmap"
              icono="mdi-map-check-outline"
              :edicion="is_edit"
            ></ctrlgeo>

            <ctrlurl :schema="schema.ctrls.url" :edicion="is_edit"></ctrlurl>

            <div style="display:flex">
              <v-text-field
                style="width:20rem"
                v-bind="$input"
                v-model="schema.ctrls.contacto.value"
                :label="schema.ctrls.contacto.label"
                :disabled="!is_edit"
              ></v-text-field>

              <v-text-field
                style="width:10rem"
                v-bind="$input"
                v-model="schema.ctrls.contactotlf.value"
                :label="schema.ctrls.contactotlf.label"
                :disabled="!is_edit"
              ></v-text-field>
            </div>
          </div>
        </div>

        <div style="display:flex;flex-wrap:wrap;justify-content:center">
          <div class="columna pt-3">
            <div class="cab">NICHOS TEMPORALIDAD</div>
            <v-sheet
              style="display:flex"
              width="15rem"
              height="230px"
              v-bind="$cfg.styles.marco"
            >
              <div style="width:6rem; display:flex; flex-direction:column">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.n01.value"
                  :label="schema.ctrls.n01.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.n02.value"
                  :label="schema.ctrls.n02.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.n03.value"
                  :label="schema.ctrls.n03.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.n04.value"
                  :label="schema.ctrls.n04.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.n05.value"
                  :label="schema.ctrls.n05.label"
                  :disabled="!is_edit"
                ></v-text-field>
              </div>

              <div style="width:7rem; display:flex; flex-direction:column">
                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.n11.value"
                  :label="schema.ctrls.n11.label"
                  :disabled="!is_edit"
                ></v-input_n>
                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.n12.value"
                  :label="schema.ctrls.n12.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.n13.value"
                  :label="schema.ctrls.n13.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.n14.value"
                  :label="schema.ctrls.n14.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.n15.value"
                  :label="schema.ctrls.n15.label"
                  :disabled="!is_edit"
                ></v-input_n>
              </div>
            </v-sheet>
          </div>

          <div class="columna pt-3">
            <div class="cab">NICHOS CONCESION</div>
            <v-sheet
              style="width:15rem;display:flex"
              height="230px"
              v-bind="$cfg.styles.marco"
            >
              <div style="width:6rem; display:flex; flex-direction:column">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.n21.value"
                  :label="schema.ctrls.n21.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.n22.value"
                  :label="schema.ctrls.n22.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.n23.value"
                  :label="schema.ctrls.n23.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.n24.value"
                  :label="schema.ctrls.n24.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.n25.value"
                  :label="schema.ctrls.n25.label"
                  :disabled="!is_edit"
                ></v-text-field>
              </div>

              <div style="width:7rem; display:flex; flex-direction:column">
                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.n31.value"
                  :label="schema.ctrls.n31.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.n32.value"
                  :label="schema.ctrls.n32.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.n33.value"
                  :label="schema.ctrls.n33.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.n34.value"
                  :label="schema.ctrls.n34.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.n35.value"
                  :label="schema.ctrls.n35.label"
                  :disabled="!is_edit"
                ></v-input_n>
              </div>
            </v-sheet>
          </div>

          <div class="columna pt-3">
            <div class="cab">COLUMBARIOS</div>
            <v-sheet
              style="display:flex"
              width="15rem"
              height="230px"
              v-bind="$cfg.styles.marco"
            >
              <div style="width:6rem; display:flex; flex-direction:column">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.c01.value"
                  :label="schema.ctrls.c01.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.c02.value"
                  :label="schema.ctrls.c02.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.c03.value"
                  :label="schema.ctrls.c03.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.c04.value"
                  :label="schema.ctrls.c04.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.n05.value"
                  :label="schema.ctrls.n05.label"
                  :disabled="!is_edit"
                ></v-text-field>
              </div>
              <div style="width:7rem; display:flex; flex-direction:column">
                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.c11.value"
                  :label="schema.ctrls.c11.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.c12.value"
                  :label="schema.ctrls.c12.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.c13.value"
                  :label="schema.ctrls.c13.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.c14.value"
                  :label="schema.ctrls.c14.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.c15.value"
                  :label="schema.ctrls.c15.label"
                  :disabled="!is_edit"
                ></v-input_n>
              </div>
            </v-sheet>
          </div>

          <div class="columna pt-3">
            <div class="cab">SEPULTURAS</div>
            <v-sheet
              style="display:flex"
              width="15rem"
              height="230px"
              v-bind="$cfg.styles.marco"
            >
              <div style="width:6rem; display:flex; flex-direction:column">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.s01.value"
                  :label="schema.ctrls.s01.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.s02.value"
                  :label="schema.ctrls.s02.label"
                  :disabled="!is_edit"
                ></v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.s03.value"
                  :label="schema.ctrls.s03.label"
                  :disabled="!is_edit"
                ></v-text-field>
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.s04.value"
                  :label="schema.ctrls.s04.label"
                  :disabled="!is_edit"
                ></v-text-field>
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.s05.value"
                  :label="schema.ctrls.s05.label"
                  :disabled="!is_edit"
                ></v-text-field>
              </div>

              <div style="width:7rem; display:flex; flex-direction:column">
                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.s11.value"
                  :label="schema.ctrls.s11.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.s12.value"
                  :label="schema.ctrls.s12.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.s13.value"
                  :label="schema.ctrls.s13.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.s14.value"
                  :label="schema.ctrls.s14.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.s15.value"
                  :label="schema.ctrls.s15.label"
                  :disabled="!is_edit"
                ></v-input_n>
              </div>
            </v-sheet>
          </div>

          <div class="columna pt-3">
            <div class="cab">EXHUMACIONES</div>
            <v-sheet width="14rem" height="230px" v-bind="$cfg.styles.marco">
              <v-input_n
                v-bind="$input"
                v-model="schema.ctrls.derechos.value"
                :label="schema.ctrls.derechos.label"
                :disabled="!is_edit"
              ></v-input_n>

              <v-input_n
                v-bind="$input"
                v-model="schema.ctrls.reducrest.value"
                :label="schema.ctrls.reducrest.label"
                :disabled="!is_edit"
              ></v-input_n>

              <v-input_n
                v-bind="$input"
                v-model="schema.ctrls.traslad.value"
                :label="schema.ctrls.traslad.label"
                :disabled="!is_edit"
              ></v-input_n>

              <v-input_n
                v-bind="$input"
                v-model="schema.ctrls.reinhum.value"
                :label="schema.ctrls.reinhum.label"
                :disabled="!is_edit"
              ></v-input_n>
            </v-sheet>
          </div>
          <!-- Fin de fila -->
        </div>

        <div style="display:flex;flex-wrap:wrap;justify-content:center">
          <div class="columna pt-3">
            <div class="cab">TARIFAS</div>
            <v-sheet width="26rem" height="180px" v-bind="$cfg.styles.marco">
              <div style="display:flex">
                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.sepult.value"
                  :label="schema.ctrls.sepult.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.dinhnicho.value"
                  :label="schema.ctrls.dinhnicho.label"
                  :disabled="!is_edit"
                ></v-input_n>
              </div>
              <div style="display:flex">
                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.dinhsepult.value"
                  :label="schema.ctrls.dinhsepult.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.dinhcolumb.value"
                  :label="schema.ctrls.dinhcolumb.label"
                  :disabled="!is_edit"
                ></v-input_n>
              </div>
              <div style="display:flex">
                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.expedtit.value"
                  :label="schema.ctrls.expedtit.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.dcollapida.value"
                  :label="schema.ctrls.dcollapida.label"
                  :disabled="!is_edit"
                ></v-input_n>
              </div>
              <div style="display:flex">
                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.movlapida.value"
                  :label="schema.ctrls.movlapida.label"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.conserva.value"
                  :label="schema.ctrls.conserva.label"
                  :disabled="!is_edit"
                ></v-input_n>
              </div>
            </v-sheet>
          </div>

          <div class="columna pt-6" style=" width:700px">
            <ctrlobs3
              :schemaComponente="schema.ctrls.obs"
              :schema="schema"   
              :record="accionRow"
              :edicion="is_edit">
            </ctrlobs3>
          </div>
        </div>
      </div>
    </v-sheet>
  </div>
</template>


<script>

  import { mixinMto } from "@/mixins/mixinMto.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const btramto = () => plugs.groute("btramto_new.vue", "comp");
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
  const ctrlgeo = () => plugs.groute("ctrlgeo.vue", "comp");
  const ctrlurl = () => plugs.groute("ctrlurl.vue", "comp");
  const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");
  const common_Docs = () => plugs.groute("common_Docs.vue", "comp");

  export default {
    mixins: [mixinMto],
    components: { base_Header, btramto, ctrlfinder, ctrlgeo, ctrlurl, ctrlobs3, common_Docs },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: null},
      disparo: { type:Boolean, default:false },      
      componenteTipo: { type:String, default:'M' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: null,
        api:'cemen_M',
        stName:'stMcemen',
        disparoFinder:false, 

        itemsGrupo: []
      };
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;  

        // entorno
        this.Entorno.header.header= true;
        this.Entorno.header.titulo = "Cementerios";
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.standardM));

        // items adicionales
        
      },

      
    }
  };
</script>
